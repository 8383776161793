@import "./main.scss";

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}
html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  color: $paragraph;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-size: 1em;
  font-family: inherit;
}

a {
  color: inherit;
  text-decoration: none;
}

p {
  font-size: 1.8rem;
  @include medium-devices {
    font-size: 3rem;
    width: 100%;
    max-width: 900px;
  }
}

h1,
h2 {
  color: $primary;
}
h1,
h2,
h3,
h4,
h5 {
  font-family: caecilia, sans-serif;
  font-weight: 700;
  font-style: normal;
}
pre {
  word-wrap: break-word;
}
.relative {
  position: relative;
}

.visible-md {
  display: none;
  @include medium-devices {
    display: block;
  }
}

// * {
//   border: 0.5px solid red;
// }
